@import "~bootstrap/scss/bootstrap";
@import "./components/RootLayout/root-layout";
@import "./components/Header/header";
@import "./components/Hero/hero";
@import "./pages/Home/home";
@import "./pages/About/about";
@import "pages/Contact/contact-page";
@import "./pages/NotFound/not-found";
@import "./components/CardsGrid/cards-grid";
@import "./components/Footer/footer";
@import "pages/Services/TensileTest/tensile-test";
@import "components/ServiceHeader/service-header";
@import "pages/MechanicTestCalendar/mechanic-test-calendars";
@import "pages/ImpactCalendar/impact-calendar";
@import "pages/DurometerCalendar/durometer-calendar";

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}