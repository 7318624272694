.root-layout__ {
  &ctn {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &main {
    flex: 1 1 auto;
    flex-grow: 2;
  }
}
