.quote-request__ {
  &ctn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 1rem;
    overflow-y: auto;
  }
}
