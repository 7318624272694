.calendar__ {
  &ctn {
    width: 100%;
    height: 100%;
    padding: 0;
  }
}

.tab-content {
  height: 100%;
}