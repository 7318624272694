.footer-container {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  padding: 16px;
}

.footer-copyright {
  display: flex;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  padding: 16px 16px 0 16px;
  text-align: center;
}
