.reset-password__ {
  &ctn {
    background: rgb(97, 98, 105);
    background: linear-gradient(
      90deg,
      rgb(55, 57, 63) 0%,
      rgba(87, 91, 99, 1) 24%,
      rgba(129, 134, 152, 1) 85%,
      rgba(158, 165, 177, 1) 98%
    );
  }

  &form-ctn {
    width: 420px;
    height: fit-content;
  }

  &button-ctn {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    padding-right: 12px;
  }

  &back-link {
    &:hover {
      border: 1px solid black;
    }
  }
}

//.was-validated .form-control:invalid, .form-control.is-invalid {
//  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjU2IDI1NiI+PHBhdGggZmlsbD0iI2RjMzU0NSIgZD0iTTI0Ny4zMSAxMjQuNzZjLS4zNS0uNzktOC44Mi0xOS41OC0yNy42NS0zOC40MUMxOTQuNTcgNjEuMjYgMTYyLjg4IDQ4IDEyOCA0OFM2MS40MyA2MS4yNiAzNi4zNCA4Ni4zNUMxNy41MSAxMDUuMTggOSAxMjQgOC42OSAxMjQuNzZhOCA4IDAgMCAwIDAgNi41Yy4zNS43OSA4LjgyIDE5LjU3IDI3LjY1IDM4LjRDNjEuNDMgMTk0Ljc0IDkzLjEyIDIwOCAxMjggMjA4czY2LjU3LTEzLjI2IDkxLjY2LTM4LjM0YzE4LjgzLTE4LjgzIDI3LjMtMzcuNjEgMjcuNjUtMzguNGE4IDggMCAwIDAgMC02LjVNMTI4IDE5MmMtMzAuNzggMC01Ny42Ny0xMS4xOS03OS45My0zMy4yNUExMzMuNSAxMzMuNSAwIDAgMSAyNSAxMjhhMTMzLjMgMTMzLjMgMCAwIDEgMjMuMDctMzAuNzVDNzAuMzMgNzUuMTkgOTcuMjIgNjQgMTI4IDY0czU3LjY3IDExLjE5IDc5LjkzIDMzLjI1QTEzMy41IDEzMy41IDAgMCAxIDIzMS4wNSAxMjhjLTcuMjEgMTMuNDYtMzguNjIgNjQtMTAzLjA1IDY0bTAtMTEyYTQ4IDQ4IDAgMSAwIDQ4IDQ4YTQ4LjA1IDQ4LjA1IDAgMCAwLTQ4LTQ4bTAgODBhMzIgMzIgMCAxIDEgMzItMzJhMzIgMzIgMCAwIDEtMzIgMzIiLz48L3N2Zz4=") !important;
//}
