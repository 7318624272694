.scheduler-sidebar__ {
  &btn-link {
    border-radius: 0;
    width: 100%;
    height: 48px;
    color: #ffffff8c;
    border-left: 2px solid rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
    &:hover {
      color: #ffffff8c;
      &:active {
        color: #ffffff8c;
      }
    }
  }

  &ctn_desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    border-top: 1px solid #ffffff8c;
    margin: 0;
    overflow-y: auto;
  }

  &ctn_mobile {
    display: block;
    position: fixed;
    top: 56px;
    left: 0;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.13);
    height: 100vh;
    width: 280px;
    border-top: 1px solid #ffffff8c;
    margin: 0;
    overflow-y: auto;
  }
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  border-left: 2px solid #ffffff8c;
  color: #ffffff8c;
}
