.cards-grid {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-gap: 1rem;
    padding: 1rem;
  }

  &__item {
  }
}