.user-add-page__ {
  &ctn {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &alert {
    position: absolute;
    top: 5rem;
    width: 50%;
  }

  &photo {
    &-ctn {
      border-radius: 8px 8px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 240px;
      background-color: blue;
    }
    width: 72px;
    height: 72px;
  }
}
