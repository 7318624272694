.hero-ctn {
  padding: 0;
  height: 60vh;
}

.hero-img {
  display: block;
  object-fit: cover;
  width: inherit;
  height: inherit;
}
