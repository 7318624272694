.scheduler-layout__ {
  &ctn {
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }

  &content-wrapper {
    padding: 0;
    margin-top: 56px;
    display: flex;
    flex-direction: row;
    height: calc(100vh - 56px);
  }

  &sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 280px;
    border-top: 1px solid rgba(89, 82, 82, 0.55);
    margin: 0;
  }
}
